import React from "react";


const CloudMigration = ({ data, locale }) => {
    return (
        <div className="cloudMigration">
            <div className="cloudMigrationTitle">{data.title}</div>
            <div className="cloudMigrationHeading">{data.heading}</div>
            <div className="cloudMigrationDescription">{data.des1}</div>
            <div className="cloudMigrationDescription">{data.des2}</div>
            <div className="opsBtnwrap pb-4">
                <a href={`/successstories/Migration-Of-Et-Network-To-AWS/`} className="opsBtnlink">
                    <p>{data.btn}</p>
                </a>
            </div>
        </div>
    )
}

export default CloudMigration;


