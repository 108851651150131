import React from 'react'
import { navigate } from "gatsby";

function Heading({data}) {
    return (
        <div className="upper_class">
           
            <div className="successStoriesSection1">
                <div className="successStoriesSection1Title">{data.title}</div>
                <h1 className="successStoriesSection1Heading">{data.heading1} <span style={{ color: "#58C9E8", marginBottom: "0px" }}>{data.heading2}</span></h1>
                
            </div>
        </div>
    )
}

export default Heading
