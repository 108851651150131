import React, { useState, useEffect } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import Img from "gatsby-image";

const ExploreSuccessStories = ({ locale, successStoryData }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiSuccessStoryLists {
        edges {
          node {
            locale
            slug
            title
            heading
            Industry {
              value
            }
            services {
              value
            }
            id
            image {
              localFile {
                childImageSharp {
                  fixed {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            description3_2
          }
        }
      }
    }
  `).allStrapiSuccessStoryLists.edges;
  const getData = data.filter((node) => node.node.locale === locale);
  // Array of all Cloud News
  const allNews = getData;

  // State for the list
  const [list, setList] = useState([]);
  const handlefilter = () =>
    dropdownvalue.industry === "Industry" && dropdownvalue.service === "Service"
      ? allNews
      : dropdownvalue.industry === "Industry"
      ? allNews.filter(
          (val) => val.node.services.value === dropdownvalue.service
        )
      : dropdownvalue.service === "Service"
      ? allNews.filter(
          (val) => val.node.Industry.value === dropdownvalue.industry
        )
      : allNews.filter(
          (val) =>
            val.node.Industry.value === dropdownvalue.industry &&
            val.node.services.value === dropdownvalue.service
        );
  const [dropdownvalue, setDropdownvalue] = useState({
    industry: "Industry",
    service: "Service",
  });
  useEffect(() => {
    const updatedData = handlefilter();
    setList([...updatedData.slice(0, 3)]);
  }, [dropdownvalue]);
  // State to trigger Load more
  const [loadMore, setLoadMore] = useState(false);

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allNews.length > 3);

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true);
  };

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const allNewsFilter = handlefilter();
      const isMore = currentLength < allNewsFilter.length;
      const nextResults = isMore
        ? allNewsFilter.slice(currentLength, currentLength + 3)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]);

  //Check if there is more
  useEffect(() => {
    const allNewsFilter = handlefilter();
    const isMore = list.length < allNewsFilter.length;
    setHasMore(isMore);
  }, [list]);

  return (
    <div className="exploreSuccessStories" style={{ backgroundColor: "white" }}>
      <div className="containerwrap">
        <div className="exploreSuccessStoriesTitle">
          {successStoryData.title}
        </div>
        <div
          className="exploreSuccessStoriesHeading"
          style={{ color: "black" }}
        >
          {successStoryData.heading}
        </div>
        {/* <p style={{ textAlign: "center" }}>Our approach process is what sets us apart </p> */}
        <div className="select_box_success">
          <select
            onChange={(e) =>
              setDropdownvalue({ ...dropdownvalue, industry: e.target.value })
            }
          >
            {successStoryData.list.industry.map((val, index) => (
              <option key={index}>{val}</option>
            ))}
          </select>
          <select
            onChange={(e) =>
              setDropdownvalue({ ...dropdownvalue, service: e.target.value })
            }
          >
            {successStoryData.list.services.map((val, index) => (
              <option key={index}>{val}</option>
            ))}
          </select>
        </div>
        <div className="blogsCards">
          {list.map((data, index) => (
            <div
              className="blogCard"
              key={index}
              style={{ marginBottom: "20px" }}
            >
              <Link to={`/successstories/${data.node.slug}/`}>
                <div className="blog_img">
                  {data.node.image && data.node.image.localFile && (
                    <Img
                      fixed={data.node.image.localFile?.childImageSharp.fixed}
                      alt="blog"
                      className="blogsCardImage"
                    />
                  )}
                </div>
                <div className="successStoryTitle">{data.node.title}</div>
                <div className="successStoryHeading">{data.node.heading}</div>
                <div className="resouce_content text-black">
                  {data.node.description3_2}
                </div>
              </Link>
            </div>
          ))}
        </div>
        {hasMore ? (
          <div className="center_btn">
            {" "}
            <button className="successStoryBtn" onClick={handleLoadMore}>
              {successStoryData.btn}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ExploreSuccessStories;
